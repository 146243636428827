import React from 'react'

import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout>
      <div>
        <h1>Toast is ready for enterprise!</h1>
        <p>
          We support full on-premise installation. <br />
          Shipped as a docker container for a seamless setup.
        </p>

        <p>
          Request your&nbsp;
          <a href="https://docs.google.com/forms/d/14ASA7WTmoxLIdSN-dYRwGBsQxTgYmtGsmvskl_SIK40/viewform?edit_requested=true">
            Toast Enterprise trial
          </a>
          &nbsp;or contact us at <b>support@toast.ninja</b>
        </p>
      </div>
    </Layout>
  )
}

export default IndexPage
